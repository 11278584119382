import React from "react";
import styles from "./Landingscreen.module.scss";
import Furnace from "../furnace/Furnace";

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { clusterApiUrl } from '@solana/web3.js';
import { SolflareWalletAdapter, PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
require('@solana/wallet-adapter-react-ui/styles.css');


function Landingscreen() {

  const network = WalletAdapterNetwork.Devnet;
  // const endpoint = clusterApiUrl(network);
  const endpoint = "https://solana-devnet.g.alchemy.com/v2/kC2SR1AnVj4wsyjCR_mLvBmxstVVUtGK"
  const wallets = [new SolflareWalletAdapter(), new PhantomWalletAdapter()];

  return (
    <div className={styles.main}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletModalProvider>
            <DndProvider backend={HTML5Backend}>
              <Furnace />
            </DndProvider>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </div>
  );
}

export default Landingscreen;
