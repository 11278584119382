import './App.css';
import { useEffect } from "react";
import init from 'cards';
import Landingscreen from './screens/landingscreen/LandingScreen';

function App() {
  useEffect(() => {
    init()
  }, []);


  return (
    <div className="App">
      <Landingscreen />
     </div>
  );
}

export default App;
