import styles from "./Card.module.scss";
import { useDrag } from 'react-dnd';
import { useState } from "react";

function Card({data, onAddCard}) {
  const [{ isDragging }, drag] = useDrag(() => ({
    item: { data: data },
    type: "card",
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }))

  const [showPopup, setShowPopup] = useState(false);

  const onHandleClick = () => {
    setShowPopup(!showPopup);

    if ('ontouchstart' in document.documentElement) {
      setShowPopup(!showPopup);
    }
  }
  
  const onClickCard = (id) => {
    setShowPopup(false);  
    onAddCard(data, id);
  }
 
  return (
    <div className={styles.main}>
      <div 
        className={styles.nftImgContainer} 
        ref={drag} 
        style={{opacity: isDragging ? 0.9 : 1 }}
        onClick={onHandleClick}
      >
        <img src={data?.json.image} alt={data?.json.name} />

      </div>
      { showPopup 
        ? <div className={styles.popup} >
            <div onClick={() => onClickCard(0)}>1</div>
            <div onClick={() => onClickCard(1)}>2</div>
            <div onClick={() => onClickCard(2)}>3</div>
            <div onClick={() => onClickCard(3)}>4</div>
            <div onClick={() => onClickCard(4)}>5</div>
          </div>
        : null
      }
    </div>
  );
}

export default Card;
