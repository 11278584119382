import { useState, useEffect } from "react";

import styles from "./CardSlot.module.scss";
import frameSlotCard from '../../images/Furnace/frameSlotCard.svg';
import { useDrop } from 'react-dnd';
import iconClose from '../../images/iconClose.png';

function CardSlot({onAddCard, data}) {
  const [img, setImg] = useState(null);
  const [isCloseShown, setIsCloseShown] = useState(false);

  const [{ isOver }, drop] = useDrop({
    accept: "card",
    drop: (e) => {
      onAddCard(e.data);
      setImg(e.data.json.image);
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
    }),
  })

  useEffect(() => {
    if (!data) {
      setImg(frameSlotCard);
    } else {
      setImg(data.json.image)
    }
  },[data]);

  const onClose = () => {
    setImg(null);
    onAddCard(null);
  }
  
  return (
    <div className={styles.main} ref={drop} onMouseEnter={() => setIsCloseShown(true)} onMouseLeave={() => setIsCloseShown(false)}>
      { img 
        ? <img src={img} className={styles.card} alt="cardImg" />
        : <img src={frameSlotCard} className={styles.slot} alt="slot" />
      }
      
      { isCloseShown && data
        ? <div className={styles.closeWrap} onClick={() => onClose()}><img src={iconClose} alt="close"></img></div>
        : null
      }
      
    </div>

  );
}

export default CardSlot;
