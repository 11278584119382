import styles from "./Furnace.module.scss";
import { useWallet, useConnection, useAnchorWallet } from '@solana/wallet-adapter-react';
import { WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { Metaplex, walletAdapterIdentity } from "@metaplex-foundation/js";
import * as anchor from "@project-serum/anchor";
import { cloneDeep, sortBy } from 'lodash';
import { get_threshold } from 'cards';
import cls from 'classnames';
import Lottie from 'lottie-react';

import barEmpty from '../../images/Furnace/barEmptyRarity.svg';
import oprorBW from '../../images/Furnace/iconOpror1.svg';
import oprorBW2 from '../../images/Furnace/iconOpror2.svg';
import walletIcon from '../../images/Furnace/iconWallet.svg';
import gaugeFail from '../../images/Furnace/lineFail1.svg';
import gaugeCommon from '../../images/Furnace/lineCommon1.svg';
import gaugeUncommon from '../../images/Furnace/lineUncommon1.svg';
import gaugeRare from '../../images/Furnace/lineRare1.svg';
import gaugeEpic from '../../images/Furnace/lineEpic1.svg';
import gaugeLegendary from '../../images/Furnace/lineLegendary1.svg';
import barRate from '../../images/Furnace/barRate.svg';
import { useEffect, useState } from "react";
import { findProgramAddressSync } from "@project-serum/anchor/dist/cjs/utils/pubkey";
import loadingOpror from '../../images/loadingOpror.json';

import idl from 'idl/furnace.json';
import { Program } from "@project-serum/anchor";
import { ComputeBudgetProgram, Keypair, PublicKey, SystemProgram, SYSVAR_RENT_PUBKEY, SYSVAR_SLOT_HASHES_PUBKEY, Transaction } from "@solana/web3.js";
import { associatedAddress, TOKEN_PROGRAM_ID } from "@project-serum/anchor/dist/cjs/utils/token";
import { getAssociatedTokenAddress } from "@solana/spl-token";
import Card from "../../components/card/Card";
import CardSlot from "../../components/cardslot/CardSlot";
import SliderFn from "../../components/slider/Slider";

function Furnace() {
  const wallet = useWallet();
  const { connection } = useConnection();
  const [allNfts, setAllNfts] = useState([]);
  const [cardList, setCardList] = useState([null, null, null, null, null]);
  const [badgeChances, setBadgeChances] = useState({});
  const [badgeChancesTotal, setBadgeChancesTotal] = useState({});
  const [parsedCardList, setParsedCardList] = useState({});
  const [gas, setGas] = useState(0);
  const [badge, setBadge] = useState(null);
  const [showBadge, setShowBadge] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showNoBadge, setShowNoBadge] = useState(false);
  const [tooltipToShow, setTooltipToShow] = useState(null);
  const [showOnLoadError, setShowOnLoadError] = useState(false);

  const anchorWallet = useAnchorWallet();
  const provider = new anchor.AnchorProvider(connection, anchorWallet, { commitment: "confirmed" });
  const program = new Program(idl, idl.metadata.address, provider)
  const badgeCollectionKey = new PublicKey("7PtVEPr1dHL8T49EFskfSTTsQQcbrEaqGdYxprGs3TQa");
  const cardCollectionKey = new PublicKey("EYvsmdCauuagYHkmai4dbdpZCeduUS1FA84G74c5dWFg");
  const oprorKey = new PublicKey("oprorE2VdT8BBJgptY9tJtHKGb64LcJ1FBK5Yy2UPEK");

  const metaplex = Metaplex.make(connection).use(
    walletAdapterIdentity(wallet)
  );

  const onAddCard = (card, slot) => {
    const list = [...cardList];
    const out = cloneDeep(list);

    const checkDuplicated = out.filter(e => e?.uri === card?.uri);

    if (checkDuplicated.length > 0) {
      out.forEach((e, idx) => {
        if (out[idx]?.uri === card?.uri) {
          out[idx] = null;
        }
      })
    }

    out[slot] = card;
    const cardListParsed = []

    out.forEach(card => {
      if (card) {
        const cardToCheckNameData = card?.json.name.split(" ");
        const cardToCheckName = cardToCheckNameData[0];
        const cardToCheckNumber = Number(cardToCheckNameData[2].split("#")[1]);

        const cardToCheck = { number: cardToCheckNumber, class: cardToCheckName }

        cardListParsed.push(cardToCheck);
      }
    })
    setCardList(out);

    let badgeChancesOut

    try {
      badgeChancesOut = get_threshold(cardListParsed, gas);
      setParsedCardList(cardListParsed);
      setBadgeChances(badgeChancesOut);
      setBadgeChancesTotal(badgeChancesOut.success + badgeChancesOut.fail + badgeChancesOut.hyper);
    } catch (error) {
      setParsedCardList({});
      setBadgeChances({});
      setBadgeChancesTotal({});
    }
  }

  const onChangeSlider = e => {
    setGas(Number(e));
    try {
      const badgeChancesOut = get_threshold(parsedCardList, Number(e));
      setBadgeChances(badgeChancesOut);
      setBadgeChancesTotal(badgeChancesOut.success + badgeChancesOut.fail + badgeChancesOut.hyper);
    } catch (error) {
      setBadgeChances({});
      setBadgeChancesTotal({});
    }

  }

  const burnNft = async () => {
    const checkEmpty = cardList.filter(card => card !== null);
    if (checkEmpty.length > 0) {
      //const tx = new Transaction()
      setLoading(true);
      const cardCollection = await metaplex.nfts().findByMint({ mintAddress: cardCollectionKey });
      const badgeCollection = await metaplex.nfts().findByMint({ mintAddress: badgeCollectionKey });

      const [statePda, _stateBump] = findProgramAddressSync([Buffer.from("furnace")], program.programId);
      const [burnRecordPda, _burnBump] = findProgramAddressSync([Buffer.from("furnace-burn"), wallet.publicKey.toBytes()], program.programId);
      let txs = new Array();

      txs.push({
        tx: await program.methods.newBurn(gas).accounts({
          burnRecord: burnRecordPda,
          oprorKey,
          state: statePda,
        }).transaction()
      });

      let burnTx = new Transaction();
      for (const card of cardList) {
        if (card) {
          const tokenAccount = await associatedAddress({ owner: provider.publicKey, mint: card?.mint.address });
          burnTx.add(
            await program.methods.burn().accounts({
              state: statePda,
              burnRecord: burnRecordPda,
              metadata: card.metadataAddress,
              mint: card.mint.address,
              tokenAccount,
              masterEditionAccount: card.edition.address,
              foundersCollectionMint: cardCollection.mint.address,
              foundersCollectionMetadata: cardCollection.metadataAddress,
              metadataProgram: metaplex.programs().getTokenMetadata().address,
              tokenProgram: TOKEN_PROGRAM_ID,
            }).instruction()
          );
        }
      }
      txs.push({ tx: burnTx });

      const mint = new Keypair();
      const metadata = metaplex.nfts().pdas().metadata({ mint: mint.publicKey });
      const edition = metaplex.nfts().pdas().masterEdition({ mint: mint.publicKey });
      const tokenAccount = await getAssociatedTokenAddress(mint.publicKey, provider.wallet.publicKey);

      const badgeCollectionAuthorityRecord = metaplex.nfts().pdas().collectionAuthorityRecord({
        mint: badgeCollection.mint.address,
        collectionAuthority: statePda,
      });
      
      const compute = ComputeBudgetProgram.setComputeUnitLimit({ units: 300000 });
      txs.push({
        tx: await program.methods.furnace().accounts({
          state: statePda,
          oprorKey,
          mint: mint.publicKey,
          metadata,
          edition,
          tokenAccount,
          burnRecord: burnRecordPda,
          badgeCollectionMint: badgeCollection.mint.address,
          badgeCollectionMetadata: badgeCollection.metadataAddress,
          badgeCollectionMasterEdition: badgeCollection.edition.address,
          badgeCollectionAuthorityRecord,
          metadataProgram: metaplex.programs().getTokenMetadata().address,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
          recentSlothashes: SYSVAR_SLOT_HASHES_PUBKEY,
        }).preInstructions(compute).transaction(),
        signers: [mint]
      });

      await provider.sendAll(txs);
      getBadge(mint.publicKey);
    }
  }

  const reMint = async () => {
    setLoading(true);
    const cardCollection = await metaplex.nfts().findByMint({ mintAddress: cardCollectionKey });
    const badgeCollection = await metaplex.nfts().findByMint({ mintAddress: badgeCollectionKey });

    const [statePda, _stateBump] = findProgramAddressSync([Buffer.from("furnace")], program.programId);
    const [burnRecordPda, _burnBump] = findProgramAddressSync([Buffer.from("furnace-burn"), wallet.publicKey.toBytes()], program.programId);
    let txs = new Array();

    let burnTx = new Transaction();

    const mint = new Keypair();
    const metadata = metaplex.nfts().pdas().metadata({ mint: mint.publicKey });
    const edition = metaplex.nfts().pdas().masterEdition({ mint: mint.publicKey });
    const tokenAccount = await getAssociatedTokenAddress(mint.publicKey, provider.wallet.publicKey);

    const badgeCollectionAuthorityRecord = metaplex.nfts().pdas().collectionAuthorityRecord({
      mint: badgeCollection.mint.address,
      collectionAuthority: statePda,
    });

    const compute = ComputeBudgetProgram.setComputeUnitLimit({ units: 300000 });
      txs.push({
        tx: await program.methods.furnace().accounts({
          state: statePda,
          oprorKey,
          mint: mint.publicKey,
          metadata,
          edition,
          tokenAccount,
          burnRecord: burnRecordPda,
          badgeCollectionMint: badgeCollection.mint.address,
          badgeCollectionMetadata: badgeCollection.metadataAddress,
          badgeCollectionMasterEdition: badgeCollection.edition.address,
          badgeCollectionAuthorityRecord,
          metadataProgram: metaplex.programs().getTokenMetadata().address,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          rent: SYSVAR_RENT_PUBKEY,
          recentSlothashes: SYSVAR_SLOT_HASHES_PUBKEY,
        }).preInstructions(compute).transaction(),
        signers: [mint]
      });

      setShowOnLoadError(false);
      await provider.sendAll(txs);
      getBadge(mint.publicKey);
  }

  const getBadge = async (mint) => {
    try {
      const badge = await metaplex.nfts().findByMint({ mintAddress: mint });

      if (badge) {
        setShowBadge(true);
        setBadge(badge.json.image)
      } else {
        setShowNoBadge(true);
      }

    } catch (error) {
      switch (error.name) {
        case "AccountNotFoundError":
          setShowNoBadge(true);
          break;
        default:
          setShowError(true);
          break;
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    const getNfts = async () => {
      const [burnRecordPda, _burnBump] = findProgramAddressSync(
        [Buffer.from("furnace-burn"), wallet.publicKey.toBytes()],
        program.programId
      );
      try {
        let burnAccount = await program.account.burnRecord.fetch(burnRecordPda);
        setShowOnLoadError(true);
        return;
      } catch (e) { }

      const allAccountNfts = await metaplex.nfts().findAllByOwner({ owner: metaplex.identity().publicKey })
      const accountOprorNfts = allAccountNfts.filter(e => e.collection.address.toBase58() === cardCollectionKey.toBase58())

      const ownNftsData = []

      await Promise.all(accountOprorNfts.map(async (e) => {
        const out = await metaplex.nfts().load({ metadata: e });
        ownNftsData.push(out);
      }));
      setAllNfts(sortBy(ownNftsData, (e) => e.json.name));
    }
    if (wallet.publicKey) {
      getNfts()
    }
  }, [wallet]);
  // este es el bueno
  // CwnzoxQLpdWf4JSmPTtHEzNCVR9Xdo8YiJzVtQKMUYSy

  const checkThreshold = () => {
    const threshold = badgeChances.success;
    let out = "0%";
    switch (true) {
      case threshold <= 499:
        out = `${threshold / 499 * 20}%`;
        break;
      case threshold > 499 && threshold <= 749:
        out = `${((threshold - 499) / 250 * 20) + 20}%`;
        break;
      case threshold > 749 && threshold <= 1299:
        out = `${((threshold - 749) / 550 * 20) + 40}%`;
        break;
      case threshold > 1299 && threshold <= 1999:
        out = `${((threshold - 1299) / 700 * 20) + 60}%`;
        break;
      case threshold > 2000 && threshold <= 2599:
        out = `${((threshold - 2000) / 599 * 20) + 80}%`;
        break;
      case threshold >= 2600:
        out = "100%";
        break;
      default:
        out = "0%";
        break;
    }
    return out;
  }
  const checkThresholdColor = () => {
    const threshold = badgeChances.success;
    let out = "#dedede";

    switch (true) {
      case threshold <= 499:
        out = "#dedede";
        break;
      case threshold > 499 && threshold <= 749:
        out = "#00936f";
        break;
      case threshold > 749 && threshold <= 1299:
        out = "#2d48ab";
        break;
      case threshold > 1299 && threshold <= 1999:
        out = "#732380";
        break;
      case threshold > 2000 && threshold <= 2599:
        out = "#d18401";
        break;
      case threshold > 2600:
        out = "#b5013c";
        break;
      default:
        out = "#dedede";
        break;
    }
    return out;
  }
  const getRate = (e) => {
    const out = badgeChances[e] / badgeChancesTotal * 100;
    if (isNaN(out)) {
      return 0;
    }
    return out;
  }
  const reset = () => {
    window.location.reload()
  }

  const onClickShowTooltip = (id) => {
    if (id === tooltipToShow) {
      setTooltipToShow(null);
    } else {
      setTooltipToShow(id)
    }
  }

  return (
    <div className={styles.main}>
      <div className={cls(
        { [styles.blurWrap]: showBadge || loading || showError || showNoBadge || showOnLoadError},
        styles.wrap
      )}>
        <div className={styles.header}>
          <h3>FURNACE</h3>
          <div className={styles.underline} />
        </div>
        <div className={styles.content}>

          <div className={styles.leftSideWrap}>
            <div className={styles.nftHeader}>Your NFTs <img src={walletIcon} /></div>
            <div className={styles.divisor} />
            <div className={styles.cardList}>
              {allNfts.map((e, idx) => <Card data={e} key={idx} onAddCard={(e, f) => onAddCard(e, f)} />)}
            </div>
          </div>

          <div className={styles.rightSideWrap}>
            <div className={styles.slotWrap}>
              {cardList.map((e, idx) => <CardSlot data={e} slot={idx} key={idx} onAddCard={(e) => onAddCard(e, idx)} />)}
            </div>
            <div className={styles.loaderMain}>
              <h4>Rarity Threshold</h4>
              <div className={styles.loaderWrap}>
                <img src={barEmpty} className={styles.loaderBg} />
                <img src={oprorBW} className={styles.oprorBW} />
                {badgeChances.success > 2600
                  ? <img src={oprorBW2} className={styles.oprorBWActive} />
                  : null
                }

                <div className={styles.loadingBar} style={{ width: checkThreshold(), background: `${checkThresholdColor()}`, boxShadow: `0 -3px 10px 1px ${checkThresholdColor()}` }} />
              </div>
              <div className={styles.gaugeWrap}>
                <div className={styles.gauge}>
                  Fail
                  <img src={gaugeFail} alt="gaugeFail" />
                </div>
                <div className={styles.gauge}>
                  <img src={gaugeCommon} alt="gaugeCommon" />
                  Common
                </div>
                <div className={styles.gauge}>
                  <img src={gaugeUncommon} alt="gaugeUncommon" />
                  Uncommon
                </div>
                <div className={styles.gauge}>
                  Rare
                  <img src={gaugeRare} alt="gaugeRare" />
                </div>
                <div className={styles.gauge}>
                  Epic
                  <img src={gaugeEpic} alt="gaugeEpic" />
                </div>
                <div className={styles.gaugeLegendary}>
                  Legendary
                </div>
              </div>
            </div>
            <div className={styles.successWrap}>
              <div className={styles.successWrapBg} />
              <div className={styles.barRate} onClick={() => onClickShowTooltip(0)}>
                <img src={barRate} className={styles.barRateBg} />
                <div className={styles.barRateFillWrap}>
                  <div className={styles.barRateFill} style={{ height: `${getRate("success")}%` }}><img src={barRate} /> </div>
                </div>
                <p>Success Rate</p>
                <span className={styles.barRateNumber}>{getRate("success").toFixed(1)}%</span>
              </div>
              <div className={styles.barRate} onClick={() => onClickShowTooltip(1)}>
                <img src={barRate} className={styles.barRateBg} />
                <div className={styles.barRateFillWrap}>
                  <div className={styles.barRateFill} style={{ height: `${getRate("hyper")}%` }}><img src={barRate} /> </div>
                </div>
                <p>Hyper Rate</p>
                <span className={styles.barRateNumber}>{getRate("hyper").toFixed(1)}%</span>
              </div>
              <div className={styles.barRate} onClick={() => onClickShowTooltip(2)}>
                <img src={barRate} className={styles.barRateBg} />
                <div className={styles.barRateFillWrap}>
                  <div className={styles.barRateFill} style={{ height: `${getRate("fail")}%` }}><img src={barRate} /> </div>
                </div>
                <p>Fail Rate</p>
                <span className={styles.barRateNumber}>{getRate("fail").toFixed(1)}%</span>
              </div>
            </div>

            <div className={styles.bottomNft}>
              <div className={styles.nftHeader}>Your NFTs <img src={walletIcon} /></div>
              <div className={styles.divisor} />
              <div className={styles.cardList}>
                {allNfts.map((e, idx) => <Card data={e} key={idx} onAddCard={(e, f) => onAddCard(e, f)} />)}
              </div>
            </div>

            <div className={styles.bottom}>
              <div className={styles.gasWrap}>
                <h4>Gas</h4>
                <SliderFn min={0} max={5} step={1} defaultValue={0} onChange={(e) => onChangeSlider(e)} />
              </div>
              <div className={styles.burnWrap}>
                <div className={styles.mobileHide}><WalletMultiButton /></div>
                <div className={styles.footerButtonWrap}>
                  <div className={styles.footerButton} onClick={() => burnNft()}>
                    <span>BURN</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {tooltipToShow === 0
            ? <div className={styles.tooltipMain} onClick={() => onClickShowTooltip(0)}>
              <div className={styles.tooltip}>
                <h4>Success Rate</h4>
                <p>This is affected by the rarity, number and repetitions of the NFTs you add in each Furnace slot. </p>
                <p>Also, the GAS you add in SOL also boosts the chances of success for getting the Badge you're looking for.</p>
              </div>
            </div>
            : null
          }
          {tooltipToShow === 1
            ? <div className={styles.tooltipMain} onClick={() => onClickShowTooltip(1)}>
              <div className={styles.tooltip}>
                <h4>Hyper Rate</h4>
                <p>This is the chance of getting a higher rarity that the once you're aiming for in the rarity Threshold</p>
                <p>You can alter this value by adding GAS and repeat some NFTs. Experimentation is key</p>
              </div>
            </div>
            : null
          }
          {tooltipToShow === 2
            ? <div className={styles.tooltipMain} onClick={() => onClickShowTooltip(2)}>
              <div className={styles.tooltip}>
                <h4>Fail Rate</h4>
                <p>There is a small base for Fail Rate, and it grows for each Furnace slot you leave empty while burning.</p>
                <p>Added GAS will slightly decrease the fail rate up to 0%, in which moment you won't be able to add more gas</p>
              </div>
            </div>
            : null
          }

        </div>
      </div>

      {loading
        ? <div className={styles.loading}>
          <Lottie animationData={loadingOpror} loop={true} />
          <span>Loading ...</span>
        </div>
        : null
      }

      {showError
        ? <div className={styles.loading}>
          <span>An error has ocurred, please try again later</span>
        </div>
        : null
      }

      {showBadge
        ? <div className={styles.popupWrap}>
          <img src={badge} alt="badge" />
          <div>Congratulations!</div>
          <div className={styles.footerButtonWrap}>
            <div className={styles.footerButton} onClick={() => reset()}>
              <span>Go Back</span>
            </div>
          </div>
        </div>
        : null
      }

      {showNoBadge
        ? <div className={styles.popupWrap}>
          <div>Tough luck!</div>
          <div>Didn't earn a Badge. Try again with better cards!</div>
          <div className={styles.footerButtonWrap}>
            <div className={styles.footerButton} onClick={() => reset()}>
              <span>Go Back</span>
            </div>
          </div>
        </div>
        : null
      }

      {showOnLoadError
        ? <div className={styles.popupWrap}>
          <div>We detected an incomplete mint</div>
          <div>Please press continue to retry it.</div>
          <WalletMultiButton />
          <div>&nbsp;</div>
          <div className={styles.footerButtonWrap}>
            <div className={styles.footerButton} onClick={() => reMint()}>
              <span>Continue</span>
            </div>
          </div>
        </div>
        : null
      }
    </div>
  );
}

export default Furnace;
