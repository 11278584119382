import React, { useState } from 'react';

import styles from "./Slider.module.scss";
import solanaLogo from '../../images/solanaLogo.svg';

function SliderFn( {min, max, defaultValue, step, onChange} ) {

  const [value, setValue] = useState(defaultValue);
  
  const onChangeSlider = e => {
    setValue(e);
    onChange(e);
  }

  const calcValue = (value / max) * 95; 
  const shadowWidth = ((calcValue )); 
  
  return (
    <div className={styles.main}>
      <div className={styles.sliderWrap} >
        <div className={styles.sliderBg}></div>
        <input
          className={styles.sliderInput} 
          type="range"
          defaultValue={value}
          min={min}
          max={max}
          step={step}
          onChange={(e) => onChangeSlider(e.target.value)}
          />
      </div>
      <div className={styles.shadowSlider} style={{width: `calc(${shadowWidth}%)`}}></div>

      <div className={styles.inputValue}>
        <img className={styles.solanaLogo} src={solanaLogo} alt="solana logo"/>
        <div>{(value * 0.2).toFixed(1)} SOL</div>
        </div>
    </div>
  );
}

export default SliderFn